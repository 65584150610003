export function styleResetFunc() {
  const clientHeight = document.documentElement.clientHeight;
  const header = document.querySelector('#header');
  let option = {
    root: document.querySelector("#observerArea"),
    // 上下100px、左右20px手前で発火
    rootMargin: "100px 20px",
    // 交差領域が50%変化するたびに発火
    threshold: [0, 0.5, 1.0]
  };
  let observer = new IntersectionObserver((changes) => {
    for (let change of changes) {
      let rect = change.target.getBoundingClientRect();
      let h = (0 < rect.top && rect.top < clientHeight) // 対象の上端は表示領域に入っている
        || (0 < rect.bottom && rect.bottom < clientHeight) // 対象の下端は表示領域に入っている
        || (0 > rect.top && rect.bottom > clientHeight); // 上端下端も表示されてないがその間が表示されている
      if (h) {
        header.classList.remove('scrolled');
      } else {
        header.classList.add('scrolled');
      }
    }
  }, option);
  let target = document.querySelector('.xyz');
  observer.observe(target);
}

/**
 スクロール一時禁止
 **/
let scroll_control = function (e) {
  e.preventDefault();
}
export function no_scroll() {
  document.addEventListener("mousewheel", scroll_control, {
    passive: false
  });
  document.addEventListener("touchmove", scroll_control, {
    passive: false
  });
}
export function return_scroll() {
  document.removeEventListener("mousewheel", scroll_control, {
    passive: false
  });
  document.removeEventListener('touchmove', scroll_control, {
    passive: false
  });
}