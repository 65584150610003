let globalNav = $('#headerNav');
let navBtn = $('#haederNavBtn');
let scrollPos;

import {no_scroll, return_scroll} from '../common/_common';

export function headerNavFunc() {
  navBtn.on('click', function () {
    $(this).stop().toggleClass('open');
    if ($(this).hasClass('open')) {
      globalNav.addClass('show');
      no_scroll();
    } else {
      globalNav.removeClass('show');
      return_scroll();
    }
  });
}