import Swiper from 'swiper';
import {no_scroll, return_scroll} from '../common/_common';

let mainVisual = $('#mainVisual');
let mainSlider = $('#mainSlider');
let indexTop = $('#indexTop').length;
let toggleBtn = $('#toggleBtn');
let toggleContent = $('#toggleContent');
let modalBtn = $('#modalBtn');
let modalContent = $('#modalContent');
let modalInner = $('#modalInner');
let closeBtn = $('#closeBtn');
let scrollPos;

export function idxFunc() {
  if (indexTop) {
    //スワイプ実行処理
    let topSwiper, topOptions;
    topOptions = {
      loop: true,
      effect: 'fade',
      autoplay: {
        delay: 5000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
        reverseDirection: false
      },
      speed:3000,
    };

    topSwiper = new Swiper(mainSlider, topOptions);

    // 経営ビジョン・理念のボタンクリック時 開閉
    toggleBtn.on('click',function(){
      toggleBtn.stop().toggleClass('open');
      if (toggleBtn.hasClass('open')) {
        toggleBtn.text('OPEN');
        toggleContent.addClass('open').slideUp(500);
        toggleContent.parent('.aboutPhilosophy').addClass('open');
      } else {
        toggleBtn.text('CLOSE');
        toggleContent.removeClass('open').slideDown(500);
        toggleContent.parent('.aboutPhilosophy').removeClass('open');
      }
    });

    // infomationボタンクリック時 開く
    modalBtn.on('click',function(){
      modalContent.addClass('open').fadeIn(500);
      no_scroll();
    });
    // モーダル閉じるボタンクリック時 閉じる
    closeBtn.on('click',function(){
      modalContent.removeClass('open').fadeOut(500);
      return_scroll();
    });
    // モーダル外側クリック時 閉じる
    modalContent.on('click',function(){
      if(!$(event.target).closest(modalInner).length) {
        modalContent.removeClass('open').fadeOut(500);
        return_scroll();
      }
    });
  }
}