// jQueryプラグイン
import 'jquery.easing';
import { return_scroll } from '../common/_common';

/* -----------------------------------------------------
ページ内リンクのアニメーション
------------------------------------------------------- */
export function pageTopAnimationFunc() {
  let scrTop = $('#pageTop');
  scrTop.on('click', function () {
    $('body,html').animate({
      scrollTop: 0
    }, 1500, 'easeInOutQuint');
    return false;
  });
  scrTop.hover(function () {
    $(this).addClass("hover");
  }, function () {
    $(this).removeClass("hover");
  });
}

let headerHeight = $('#header').outerHeight(true);
let responsibleCheck = $('#responsibleCheck').width();
let navBtn = $('#haederNavBtn');
let globalNav = $('#headerNav');
let headerLogo = $('#headerLogo');

export function pageMoveAnkerFunc() {
  $('a[href^="#"]:not(.notScroll)').on('click', function () {
    navBtn.stop().removeClass('open');
    if (!navBtn.hasClass('open')) {
      globalNav.removeClass('show');
      headerLogo.removeClass('show');
      return_scroll();
    }
    let id = this.href.split('#')[1];
    let position = $('#' + id).get(0).offsetTop - headerHeight;
    $('body,html').animate({
      scrollTop: position
    }, 1000, 'easeInOutQuint');
    return false;
  });
}

export function pageLinkAnimationFunc() {
  let url = $(location).attr('href');
  if (url.indexOf("?id=") != -1) {
    let id = url.split("?id=");
    let target = $('#' + id[id.length - 1]);
    if(target.length){
      let position = target.offset().top - headerHeight;
      $('body,html').animate({
        scrollTop: position
      }, 1000, 'easeInOutQuint');
      return false;
    }
  }
}